<template>
  <div class="form-area">
    <div class="flex row justify-content-center">
      <div v-if="device==='desktop'" class="desktop-wrapper">
        <div v-for="(user, idx) in userList" :key="idx">
          <div class="baggage-wrapper">
            <div class="user-caption">{{ $t('booking.passenger') + (idx+1) + ' ' + user.firstName + ' ' + user.lastName }}</div>
            <baggage-information-app
              :user="user"
              :priceInfo="priceInfo"
              @updateBaggage="updateBaggageInformation"
            />
          </div>
        </div>
      </div>
      <div v-else class="mobile-wrapper">
        <div v-for="(user, idx) in userList" :key="idx">
          <div class="username-wrapper" @click="setActiveUser(idx)">
            <div class="user-caption">{{ $t('booking.passenger') + (idx+1) + ' ' + user.firstName + ' ' + user.lastName }}</div>
            <div :class="['arrow-icon', idx === activeUserIndex ? 'icon-upward' : 'icon-downward']"></div>
          </div>
          <baggage-information-app
            :user="user"
            :priceInfo="priceInfo"
            @updateBaggage="updateBaggageInformation"
            v-if="idx === activeUserIndex"
          />
        </div>
      </div>
    </div>
    <div class="total-caption">{{ $t('booking.total-additions') }} {{ totalBaggagePrice }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    BaggageInformationApp: () => import('@/components/booking/atoms/BaggageInformationApp'),
  },
  props: {
    userList: {
      type: Array,
      default: null,
    },
    priceInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
    totalBaggagePrice() {
      return `${this.baggagePrice}$`;
    },
  },
  data() {
    return {
      activeUserIndex: 0,
      baggagePrice: 0,
    }
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    this.userList.forEach(user => {
      user.baggageInfo = {
        hasHandbag: true,
        hasTrolley: this.priceInfo.trolleyPrice === null,
        hasSuitcase: this.priceInfo.suitcasePrice === null,
        hasHandbagReturn: true,
        hasTrolleyReturn: this.priceInfo.trolleyReturnPrice === null,
        hasSuitcaseReturn: this.priceInfo.suitcaseReturnPrice === null,
      }
    });

    // set active user
    this.activeUserIndex = 0;
  },
  methods: {
    setActiveUser(index) {
      this.activeUserIndex = index;
    },
    updateBaggageInformation(info) {
      // update baggage information
      const user = this.userList.find(user => user.id === info.user.id && user.groupID === info.user.groupID);
      user.baggageInfo = info.baggageInfo;

      // calculate additional baggage cost
      let price = 0;
      this.userList.forEach(user => {
        const info = user.baggageInfo;
        const cost = info ?
          (info.hasTrolley ? +this.priceInfo.trolleyPrice : 0) +
          (info.hasSuitcase ? +this.priceInfo.suitcasePrice : 0) +
          (info.hasTrolleyReturn ? +this.priceInfo.trolleyReturnPrice : 0) +
          (info.hasSuitcaseReturn ? +this.priceInfo.suitcaseReturnPrice : 0)
          : 0;
        price += cost;
      })

      this.baggagePrice = price;

      this.$emit('updateBaggagePrice', price);
    },
  },
};
</script>

<style lang="less" scoped>
 .form-area{
    font-family: "ploni";

    .desktop-wrapper{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: min-content;

      .baggage-wrapper{
        width: 299px;
        border-radius: 16px;
        border: solid 1px #C1C1C1;
        padding: 16px;
        margin: 16px;
      }
    }

    .mobile-wrapper{
      width: 353px;
      border-radius: 16px;
      border: solid 1px #C1C1C1;
      padding: 16px;
      margin: 16px;
    }

    .user-caption{
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .username-wrapper{
      display: flex;
      justify-content: space-between;
      margin: 12px 0px;

      .user-caption{
        margin-bottom: 0px;
      }

      .arrow-icon{
        width: 24px;
        height: 24px;
      }
      .icon-downward{
        background: url("data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%228%22%20viewBox%3D%220%200%2014%208%22%20fill%3D%22none%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%201L7%207L13%200.999999%22%20stroke%3D%22black%22%20stroke-width%3D%221.58358%22%20stroke-linecap%3D %22round %22 %20stroke-linejoin %3D %22round %22/%3E %3C/svg %3E") no-repeat center;
      }
      .icon-upward{
        background: url("data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%228%22%20viewBox%3D%220%200%2014%208%22%20fill%3D%22none%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%207L7%201L13%207%22%20stroke%3D%22black%22%20stroke-width%3D%221.58358%22%20stroke-linecap%3D %22round %22 %20stroke-linejoin %3D %22round %22/%3E %3C/svg %3E") no-repeat center;
      }
    }

    .total-caption{
      font-size: 37px;
      line-height: 55px;
      font-weight: 700;
      text-align: center;
      margin: 16px;
    }
  }

  @media (max-width: 479px) {

  }
</style>
